import React from "react";
import { Link } from "react-router-dom";

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>

                {/* <div
                  className="btn-custom"
                  style={{
                    fontSize: "1.2em",
                    boxShadow:
                      "rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;",
                  }}
                >
                  <strong>Smithfield PID Annual Board Meeting</strong>
                  <div>Tuesday, April 9, 4:00 P.M.</div>
                  <div style={{ marginTop: "1.5em" }}>
                    <strong>Forney Industries HQ</strong>
                    <div>2057 Vermont Drive, Fort Collins</div>
                  </div>
                </div>

                <Link
                  to="/rsvp"
                  className="btn btn-custom btn-lg page-scroll"
                  style={{ marginTop: "1.5em" }}
                >
                  RSVP
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
