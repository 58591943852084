import React from "react";

export const Documents = (props) => {
  return (
    <div id="documents" className="text-center">
      <div
        className="col-md-10 col-md-offset-1 section-title"
        // style={{ border: "none" }}
      >
        <h2>Documents</h2>
      </div>

      <div className="container">
        <div
          class="panel-group"
          id="accordion"
          role="tablist"
          aria-multiselectable="false"
        >
          {props.data
            ? props.data.map((d, i) => (
                <div className="panel panel-default">
                  <div className="panel-heading" role="tab" id={"heading" + i}>
                    <h4 className="panel-title">
                      <a
                        role="button"
                        data-toggle="collapse"
                        href={"#collapse" + i}
                        aria-expanded="true"
                        aria-controls={"collapse" + i}
                        style={{
                          // border: "2px solid",
                          padding: "3px",
                          paddingLeft: "20px",
                          paddingRight: "20px",
                          textAlign: "center",
                        }}
                      >
                        <span> {d.title}</span>
                      </a>
                    </h4>
                  </div>

                  {/* Links::::: */}
                  <div
                    id={"collapse" + i}
                    className="panel-collapse collapse"
                    role="tabpanel"
                    aria-labelledby={"heading" + i}
                  >
                    {d.url.map((url) => (
                      <div className="panel-body" key={url + i}>
                        <a href={url[1]} target="_blank">
                          {url[0]}
                        </a>
                      </div>
                    ))}
                  </div>
                  {/* Links::::: */}
                </div>
              ))
            : "Loading..."}
        </div>
      </div>
    </div>
  );
};
