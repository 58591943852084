import React from "react";

export const Faqs = (props) => {
  return (
    <div id="faqs" className="text-center">
      <div></div>
      <div className="container">
        <div className="section-title">
          <h2>FAQS</h2>
          <p>Frequently Asked Questions</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div
                  key={`${d.name}-${i}`}
                  className="col-md-4"
                  style={{ marginTop: "30px" }}
                >
                  {" "}
                  <i className={d.icon}></i>
                  <div className="faqs-desc">
                    <h3>{d.name}</h3>
                    <ul>
                      {d.text.map((text) => (
                        <li
                          style={{
                            textAlign: "left",
                            // textJustify: "inter-character",
                            marginTop: "20px",
                          }}
                        >
                          - {text}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
