import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";

const initialState = {
  name: "",
  email: "",
  businessname: "",
  address: "",
  inquiry: "",
};
export const Contact = (props) => {
  console.log(window.location.href);
  const [{ name, email, businessname, address, inquiry }, setState] =
    useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, businessname, address, inquiry);

    // alert("Thank you for joining the mailing list!");
    //  window.location.replace("/");

    emailjs
      .sendForm(
        "service_uj4gil8",
        "contact_form",
        e.target,
        "NT_DBhMO2Qu5ipjQv"
      )
      .then(
        (result) => {
          console.log("results: ", result.text);
          clearState();

          alert("Thank you for joining the mailing list!");
          window.location.replace("/");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                  Have a question? Need to report an issue? Want to join the
                  Smithfield PID email list? Submit your inquiry here:
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    name="businessname"
                    id="businessname"
                    className="form-control"
                    rows="4"
                    placeholder="Business Name"
                    required
                    onChange={handleChange}
                  ></input>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <input
                    name="address"
                    id="address"
                    className="form-control"
                    rows="4"
                    placeholder="Business Address"
                    required
                    onChange={handleChange}
                  ></input>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <textarea
                    name="inquiry"
                    id="inquiry"
                    className="form-control"
                    rows="4"
                    placeholder="Inquiry"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Join Email List
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            {/* &copy;  */}
            2024. Created By{" "}
            <a href="https://luisascencio.com/" rel="nofollow">
              Luis Ascencio
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
